"use strict";
exports.__esModule = true;
/**
 * Serialize a `bigint` to a string
 */
exports["default"] = {
    type: 'BigInt',
    shouldTransform: function (_type, obj) {
        return typeof obj === 'bigint';
    },
    toSerializable: function (value) {
        return value + "n";
    },
    fromSerializable: function (data) {
        return BigInt(data.slice(0, -1));
    }
};
